import { Component, OnDestroy, OnInit } from '@angular/core';
import icons from '@assets/icons.json';
import { FormControl } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

interface Icon {
  icon: {
    paths: string[];
    tags: string[];
    grid: number;
  };
  properties: IconProperties;
}

interface IconProperties {
  id: number;
  order: number;
  ligatures: string;
  prevSize: number;
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private $icons: Icon[] = [];
  loading = false;
  height!: number;
  icons: Icon[] = [];
  search = '';
  searchControl = new FormControl();
  searchCtrlSub!: Subscription;

  ngOnInit(): void {
    this.height = icons.height;
    this.$icons = icons.icons;
    this.icons = icons.icons;
    this.searchCtrlSub = this.searchControl.valueChanges
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.loading = false;
        if (value.trim().length === 0) {
          this.icons = this.$icons;
        } else {
          this.icons = this.$icons.filter(i => {
            const match = i.icon.tags.filter(t => t.match(value));
            return match.length;
          });
        }
      });
  }

  clipBoard(event: Event, target: string): void {
    const symbol = document.querySelector(target);
    const current = event.currentTarget as HTMLElement;
    const parent = current.parentElement;

    if (symbol) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const viewBox = symbol.getAttribute('viewBox');
      svg.setAttribute('viewBox', viewBox || '0 0 1024 1024');
      const path = symbol.querySelector('path')?.cloneNode(true);
      if (path) {
        svg.appendChild(path as Node);
        if (!navigator.clipboard) {
          this.fallbackClipboard(svg.outerHTML);
          return;
        }
        navigator.clipboard.writeText(svg.outerHTML).then(() => {
          if (parent) {
            const copied = parent.querySelector('.icon__copied');
            copied?.classList.add('show');
            setTimeout(() => {
              copied?.classList.remove('show');
            }, 2000);
          }
        }, (err) => {
          console.error(err.message);
        });
      }
      return;
    }
    return;

  }

  private fallbackClipboard(text: string): void {
    console.log(text);
  }

  ngOnDestroy(): void {
    this.searchCtrlSub.unsubscribe();
  }
}
