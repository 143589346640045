<!-- Toolbar -->
<div class="lithium__page">
  <div class="lithium__container container">
    <div class="lithium__title">
      <h2>Lithium Icon</h2>
    </div>
    <div class="lithium__search">
      <label class="hidden" for="search"></label>
      <input id="search"
             type="search"
             placeholder="Search icon"
             [value]="search"
             [formControl]="searchControl">
    </div>
    <div class="lithium__divider"></div>
    <ul class="lithium__list" role="list">
      <li *ngIf="loading; else loaded;"
          class="loading w-full text-center p-6"
      >
        Searching...
      </li>
      <ng-template #loaded>
        <ng-container *ngIf="icons && icons.length; else notFound">
          <li class="lithium__icon" *ngFor="let icon of icons">
            <div class="icon__wrapper">
              <svg class="icon"
                   width='100%'
                   height='100%'>
                <use [attr.xlink:href]="'#lit-' + icon.properties.name"></use>
              </svg>
              <div class="icon__label" (click)="clipBoard($event, '#lit-' + icon.properties.name)">
                {{ icon.properties.name }}
              </div>
              <div class="icon__copied">Copied!</div>
            </div>
          </li>
        </ng-container>
      </ng-template>
      <ng-template #notFound>
        <li class="loading w-full text-center p-6">
          Not found, please try again.
        </li>
      </ng-template>
    </ul>
  </div>
  <svg class="hidden">
    <defs>
      <symbol *ngFor="let icon of icons"
              [attr.id]="'lit-' + icon.properties.name"
              [attr.viewBox]="'0 0 ' + height + ' ' + height">

        <path *ngFor="let path of icon.icon.paths"
              [attr.d]="path"></path>
      </symbol>
    </defs>
  </svg>
</div>
